import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams, RowDataUpdatedEvent, SelectionChangedEvent } from '@ag-grid-community/core';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomRowDataUpdatedEvent, isCustomRowDataUpdatedEvent } from '../../shared-models';
import {
  CustomSelectAllEvent,
  CustomSelectableHeaderAdditionalParams,
  CustomSelectableHeaderParams,
  CustomSelectionChangedEvent,
  isCustomSelectionChangedEvent,
} from '../../shared-models/';

@Component({
  selector: 'grid-ui-custom-selectable-header',
  templateUrl: './custom-selectable-header.component.html',
  styleUrls: ['./custom-selectable-header.component.scss'],
})
export class CustomSelectableHeaderComponent implements IHeaderAngularComp, OnDestroy {
  @ViewChild('menuButton', { read: ElementRef, static: false }) public menuButton?: ElementRef<HTMLElement>;

  public label = '';

  public selectAllForm: UntypedFormGroup;
  public selectAllControl: UntypedFormControl;
  public hideSelectAll = false;

  public enableSorting = false;
  public alwaysHideNoSort = true;
  public enableMenu = false;
  public alwaysShowMenu = false;

  public columnFilterActive = false;
  public sort: 'asc' | 'desc' | null = null;

  private params: IHeaderParams | null = null;

  private updateActiveFilterState: () => void;
  private updateSelectAllVisibility: (event: CustomRowDataUpdatedEvent | RowDataUpdatedEvent) => void;
  private updateSortState: () => void;
  private resetSelectAll: (event: CustomSelectionChangedEvent | SelectionChangedEvent) => void;

  private checkboxSub: Subscription;

  public constructor(private formBuilder: UntypedFormBuilder) {
    this.selectAllForm = this.formBuilder.group({
      selectAll: new UntypedFormControl(false),
    });
    this.selectAllControl = this.selectAllForm.controls['selectAll'] as UntypedFormControl;
    this.checkboxSub = this.selectAllControl.valueChanges.subscribe((allSelected: boolean) => {
      this.handleSelectAllChange(allSelected);
    });

    this.resetSelectAll = this.getResetSelectAllHandler();
    this.updateActiveFilterState = this.getActiveFilterStateHandler();
    this.updateSelectAllVisibility = this.getRowDataUpdatedHandler();
    this.updateSortState = this.getSortStateChangeHandler();
  }

  public agInit(params: CustomSelectableHeaderParams): void {
    this.setLocalProperties(params);

    params.column.addEventListener('sortChanged', this.updateSortState);
    this.updateSortState();

    params.column.addEventListener('filterChanged', this.updateActiveFilterState);
    this.updateActiveFilterState();

    // Note: adding the below event listener through params.column.addEventListener(...)
    // does not work, so we are binding at the API level.

    params.api.addEventListener('rowDataUpdated', this.updateSelectAllVisibility);
    this.updateActiveFilterState();

    params.api.addEventListener('selectionChanged', this.resetSelectAll);
  }

  public ngOnDestroy(): void {
    if (this.params) {
      this.params.column.removeEventListener('sortChanged', this.updateSortState);

      this.params.column.removeEventListener('filterChanged', this.updateActiveFilterState);

      // Note: adding the below event listeners through params.column.addEventListener(...)
      // does not work, so we bound and remove at the API level.

      this.params.api.removeEventListener('rowDataUpdated', this.updateSelectAllVisibility);

      this.params.api.removeEventListener('selectionChanged', this.resetSelectAll);
    }
    if (this.checkboxSub && !this.checkboxSub.closed) {
      this.checkboxSub.unsubscribe();
    }
  }

  public captureClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  public changeSort(): void {
    if (this.enableSorting && this.params) {
      // TODO: Generalize to get `supressMultisort` status and pass in event.shiftKey?
      this.params.progressSort(false);
    }
  }

  public openMenu(event: MouseEvent): void {
    event.stopPropagation();
    if (this.params && this.menuButton) {
      this.params.showColumnMenu(this.menuButton.nativeElement);
    }
  }

  public openFilter(event: MouseEvent): void {
    event.stopPropagation();
    if (this.params) {
      this.params.showFilter(this.menuButton?.nativeElement);
    }
  }

  // public refresh(params: CustomSelectableHeaderParams): boolean {

  //   this.setLocalProperties(params);
  //   return true;
  // }

  // HACK: Due to https://github.com/ag-grid/ag-grid/issues/4099  currently, the refresh
  // method is not invoked with custom parameters included at top level. When the issue
  // is addressed and the code base updated to a fixed version of ag Grid, the simplified
  // method above can be called. Until then use this HACK:
  public refresh(params: IHeaderParams): boolean {
    this.params = params;
    this.label = params.displayName || '';
    this.alwaysHideNoSort = !params.column.getColDef().unSortIcon;
    this.enableSorting = params.enableSorting;
    this.enableMenu = params.enableMenu;

    const additionalParams = params.column.getColDef().headerComponentParams as CustomSelectableHeaderAdditionalParams;

    this.alwaysShowMenu = additionalParams.suppressMenuHide;

    this.selectAllControl.setValue(additionalParams.selectAll, { emitEvent: false });

    return true;
  }

  private getActiveFilterStateHandler(): () => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return function (): void {
      if (self.params) {
        self.columnFilterActive = self.params.column.isFilterActive();
      }
    };
  }

  private getResetSelectAllHandler(): (event: CustomSelectionChangedEvent | SelectionChangedEvent) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return function (event: CustomSelectionChangedEvent | SelectionChangedEvent): void {
      if (isCustomSelectionChangedEvent(event) && event.customType === 'selectAllReset') {
        self.selectAllControl.setValue(event.resetSelectAll, { emitEvent: false });
      }
    };
  }

  private getRowDataUpdatedHandler(): (event: CustomRowDataUpdatedEvent | RowDataUpdatedEvent) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return function (event: CustomRowDataUpdatedEvent | RowDataUpdatedEvent): void {
      if (isCustomRowDataUpdatedEvent(event)) {
        switch (event.customType) {
          case 'filteredTotalUpdated':
            if (!event.filteredTotal) {
              self.hideSelectAll = true;
            } else {
              self.hideSelectAll = false;
            }
            break;
          case 'rowDataLoadingFailed':
            self.hideSelectAll = true;
            break;
        }
      }
    };
  }

  private getSortStateChangeHandler(): () => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return function (): void {
      self.sort = null;
      if (self.params) {
        if (self.params.column.isSortAscending()) {
          self.sort = 'asc';
        } else if (self.params.column.isSortDescending()) {
          self.sort = 'desc';
        }
      }
    };
  }

  private handleSelectAllChange(selectAll: boolean): void {
    if (this.params) {
      const event: CustomSelectAllEvent = {
        type: 'selectionChanged',
        customType: 'selectAllChanged',
        api: this.params.api,
        selectAll,
        context: null,
        source: 'uiSelectAll',
      };
      this.params.api.dispatchEvent(event);
    }
  }

  private setLocalProperties(params: CustomSelectableHeaderParams): void {
    this.params = params;
    this.label = params.displayName;
    this.alwaysHideNoSort = !params.column.getColDef().unSortIcon;

    this.enableSorting = params.enableSorting;
    this.enableMenu = params.enableMenu;
    this.alwaysShowMenu = params.suppressMenuHide;

    this.selectAllControl.setValue(params.selectAll, { emitEvent: false });
  }
}
